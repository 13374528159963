export const ADJECTIVES = [
  "AI",
  "apocalyptic",
  "bro",
  "bird",
  "celebrity",
  "cowboy",
  "christmas",
  "desert",
  "djungle",
  "disney",
  "donald trump",
  "drinking-game",
  "educational",
  "eighties",
  "fantasy",
  "financial",
  "forest",
  "game of thrones",
  "halloween",
  "happy",
  "galactic",
  "giant",
  "matt damon",
  "marvel",
  "multiplayer",
  "nicholas cage",
  "ninja",
  "paper",
  "pixar",
  "robot",
  "sad",
  "samuel l. jackson",
  "sixties",
  "speed",
  "slow-motion",
  "tactical",
  "tiny",
  "tron",
  "vacation",
];
export const SUBSTANTIVES = [
  "AI",
  "api",
  "blog",
  "boss-fight",
  "cards",
  "casino",
  "categories",
  "chat",
  "chess",
  "choose-your-adventure",
  "click-game",
  "clock",
  "co-op",
  "dashboard",
  "documentation",
  "dog",
  "dragon",
  "drums",
  "dungeon crawler",
  "e-commerce",
  "explained",
  "food",
  "football",
  "gist",
  "guide",
  "memory",
  "murder-mystery",
  "music",
  "network",
  "newsfeed",
  "othello",
  "pacman",
  "paint",
  "parser",
  "poker",
  "pong",
  "piano",
  "quotes",
  "quiz",
  "randomizer",
  "reviews",
  "recepies",
  "rock-paper-scissors",
  "roulette",
  "sequencer",
  "snake",
  "side-scroller",
  "t-rex",
  "tetris",
  "testing",
  "tic-tac-toe",
  "tips",
  "todo",
  "videos",
  "whack-a-mole",
  "zombies",
  "yatzy",
];
